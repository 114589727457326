import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    Checkbox,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import CancelIconOutlined from '@mui/icons-material/CancelOutlined';
import { useGetCreditReportDataMutation } from '../features/api/deepApiSlice';
import { modalStyles as style, TextFieldStyles } from '../utils/cssStyles';

const CreditReport = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [getCreditReportData, { data, isLoading, isFetching, isError, isSuccess, error }] = useGetCreditReportDataMutation();
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: async (values) => {
            await getCreditReportData({ pan_number: values.pan_number, full_name: values.name, mobile_number: values.mobile }).unwrap();
            setModalOpen(true);
        },
    });

    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const download_credit_report = (report_link) => {
        const link = document.createElement('a');
        link.href = report_link;
        // Generating a unique name with a 4-digit hash
        const hash = Math.floor(1000 + Math.random() * 9000);
        const uniqueName = `credit_report_${hash}`;
        link.download = uniqueName;
        link.click();
        link.remove();
    }

    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            >
                <Box sx={{ ...style }}>
                    <Box
                        mb={1}
                        alignItems="center"
                        display="flex"
                        sx={{ lineHeight: 1, fontSize: 'larger', fontWeight: 600 }}
                    >
                        {isSuccess && data?.data?.credit_report_link ? (
                            <>
                                Credit Report Generated Successful &nbsp;
                                <CheckCircleOutlineTwoToneIcon fontSize="small" htmlColor="green" />
                            </>
                        ) : (
                            <>
                                Credit Report Generation Failed &nbsp;
                                <CancelIconOutlined fontSize="small" htmlColor="red" />
                            </>
                        )}
                    </Box>
                    <Grid container mb={3}>
                        <Grid container flexDirection="row" my={0.5}>
                            <Grid item color="gray" sm={6}>
                                Credit Score
                            </Grid>
                            <Grid item>{data?.data?.credit_score || '-'}</Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} flexItem />
                        <Grid container flexDirection="column" my={0.5}>
                            {isSuccess && data?.data?.credit_report_link ? (
                                <>
                                    <Button
                                        variant="contained"
                                        onClick={() => download_credit_report(data?.data?.credit_report_link)}
                                        color="secondary"
                                        sx={{
                                            padding: 1.5,
                                            color: 'white',
                                            backgroundColor: 'green',
                                            '&:hover': {
                                                backgroundColor: 'darkgreen'
                                            }
                                        }}
                                    >
                                        Download Credit Report
                                    </Button>
                                </>
                            ) : (
                                <Grid flexDirection="column" my={0.5} color="red">
                                    No Record Found
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            color="secondary"
                            sx={{ padding: 0.5 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Box>
            </Modal>
            <Grid sx={{ mb: 5 }}>
                <Typography lineHeight={1} variant="h4">
                    Verification Suite
                </Typography>
            </Grid>
            <Box component="form" autoComplete="on" noValidate={false} onSubmit={formik.handleSubmit}>
                <Card>
                    <CardHeader
                        title="Credit Report"
                        subheader="The Credit Report API takes the pan number, mobile number, and full name as input, and provides provides person's credit report in PDF format."
                    />
                    <CardContent>
                        <Grid
                            container
                            item
                            xs={12}
                            mb={2}
                            width={'100vw'}
                            spacing={2}
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Grid item>
                                <InputLabel>Pan Number</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Pan Number"
                                    size="small"
                                    type="text"
                                    name="pan_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel>Full Name</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Name"
                                    size="small"
                                    type="text"
                                    name="name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel>Mobile Number</InputLabel>
                                <TextField
                                    InputProps={{
                                        sx: {
                                            display: 'flex',
                                            gap: 0.5,
                                            padding: 0.6,
                                        },
                                    }}
                                    placeholder="Mobile Number"
                                    size="small"
                                    type="text"
                                    name="mobile"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item>
                                <Checkbox checked={checked} onChange={handleChange} />
                                I authorize Deepvue Technologies Pvt Ltd to securely access and process my Credit Report through its Credit Bureau partners in full compliance with the company's privacy policy.
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={!formik.isValid || isLoading || !checked}
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                >
                                    {isLoading ? <CircularProgress size={'1.5rem'} /> : 'Submit'}
                                </Button>
                            </Grid>
                            <Grid item>
                                {isError && (
                                    <Alert severity="error">
                                        <AlertTitle>
                                            {error?.data?.message || 'Something went wrong, please try again.'}
                                        </AlertTitle>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default CreditReport;
